<template>
  <div class="example">
    <apexcharts
      ref="realtimeChart"
      type="bar"
      :options="chartOptions"
      :series="series"
    ></apexcharts>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { IncomeExpenseMonthly } from "@/services/analytics.service";
import { formatNumbers } from "@/util/formatNumbers.js";

export default {
  name: "Chart",
  components: {
    apexcharts: VueApexCharts,
  },
  props: {
    year: Number,
    fiscal_year: String,
  },
  data: function () {
    return {
      chartOptions: {
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return formatNumbers(parseFloat(val));
            },
          },
        },
        colors: ["#104873", "#EB596E"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "left",
          floating: false,
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 0,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
        },
      },
      series: [
        {
          name: "Income",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
        {
          name: "Expenses",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
    };
  },
  async mounted() {
    await this.get_income_expense_data();
  },
  watch: {
    async year(year) {
      await this.get_income_expense_data({ year });
    },
  },
  methods: {
    async get_income_expense_data() {
      await IncomeExpenseMonthly({ year: this.year }).then((res) => {
        res = res.data.analysis;
        this.series[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.series[1].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        for (let i = 0; i < res.length; i++) {
          if (res[i]._id.type === "income") {
            this.series[0].data[res[i]._id.month - 1] = parseFloat(
              this.series[0].data[res[i]._id.month - 1] +
                Math.abs(res[i].total_amount)
            ).toFixed(2);
          } else {
            this.series[1].data[res[i]._id.month - 1] = parseFloat(
              this.series[1].data[res[i]._id.month - 1] +
                Math.abs(res[i].total_amount)
            ).toFixed(2);
          }
        }
        this.$refs.realtimeChart.updateSeries(
          [
            {
              data: this.series[0].data,
            },
            {
              data: this.series[1].data,
            },
          ],
          false,
          true
        );
      });
    },
  },
};
</script>

<template>
  <div class="example">
    <apexcharts
      ref="realtimeChart"
      type="donut"
      :options="chartOptions"
      :series="series"
    ></apexcharts>
    <div class="data-labels">
      <ul>
        <li
          v-for="(label, index) in chartOptions.labels"
          :key="index"
          type="none"
        >
          <span
            class="label-color"
            :style="{ backgroundColor: chartOptions.colors[index] }"
          ></span>
          {{ label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { ExpensesBreakdown } from "@/services/analytics.service";
import { formatNumbers } from "@/util/formatNumbers.js";

export default {
  name: "Chart",
  components: {
    apexcharts: VueApexCharts,
  },
  props: {
    year: Number,
  },
  data: function () {
    return {
      chartOptions: {
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false,
          },
        },
        labels: [],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "3px",
                  fontFamily: "Rubik",
                  color: "#dfsda",
                  offsetY: 0,
                },
                value: {
                  show: true,
                  fontSize: "20px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  color: undefined,
                  offsetY: 5,
                  formatter: function (value) {
                    return formatNumbers(parseFloat(value));
                  },
                },
                total: {
                  show: true,
                  label: "Total Expenses",
                  color: "#373d3f",
                  formatter: function (w) {
                    let tot = 0.0;
                    for (let i = 0; i < w.globals.seriesTotals.length; i++) {
                      tot = tot + w.globals.seriesTotals[i];
                    }
                    return formatNumbers(parseFloat(tot));
                  },
                },
              },
            },
          },
        },
        legend: {
          show: false,
          position: "bottom",
          onItemHover: {
            highlightDataSeries: true,
          },
          horizontalAlign: "left",
          floating: false,
          width: "100%",
          height: undefined,
          offsetX: 0,
          offsetY: 0,
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 10,
            vertical: 0,
          },
        },
        colors: [
          "#FFE227",
          "#EB596E",
          "#4D375D",
          "#EC4646",
          "#A3D2CA",
          "#09015F",
        ],
        dataLabels: {
          enabled: false,
        },
      },
      series: [],
      currency: localStorage.getItem("CURRENCY"),
      colorLength: null,
      hexCharacters: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
      ],
      NUMBER_OF_CATEGORY_START: 0,
      NUMBER_OF_CATEGORY_END: 10,
    };
  },
  async mounted() {
    await this.init();
    this.generateRandomColor(this.colorLength);
  },
  watch: {
    async year() {
      await this.init();
      this.generateRandomColor(this.colorLength);
    },
  },
  methods: {
    generateNewColor() {
      let hexColorRep = "#";
      for (let i = 0; i < 6; i++) {
        const randomPosition = Math.floor(
          Math.random() * this.hexCharacters.length
        );
        hexColorRep += this.getCharacter(randomPosition);
      }
      return hexColorRep;
    },

    getCharacter(index) {
      return this.hexCharacters[index];
    },

    generateRandomColor(length) {
      let colors = [];
      for (let i = 0; i < length; i++) {
        let randomColor;
        randomColor = this.generateNewColor();
        if (randomColor === "#FFFFFF" || randomColor === "#ffff") {
          randomColor = this.generateNewColor();
        }
        colors.push(randomColor);
      }
      let labels = this.chartOptions.labels;
      if (length > 10) {
        labels = this.chartOptions.labels.slice(
          this.NUMBER_OF_CATEGORY_START,
          this.NUMBER_OF_CATEGORY_END
        );
        this.$refs.realtimeChart.updateOptions({ colors, labels });
      } else {
        this.$refs.realtimeChart.updateOptions({ colors });
      }
      this.chartOptions.colors = colors;
      this.chartOptions.labels = labels;
      this.$forceUpdate();
    },
    async init() {
      await ExpensesBreakdown({ year: this.year }).then((res) => {
        this.chartOptions.labels = [];
        this.series = [];
        res = res.data.analysis.list;
        this.colorLength = res.length;
        var total = 0;
        for (var i = 0; i < res.length; i++) {
          total = total + res[i].amount;
        }
        for (let i = 0; i < res.length; i++) {
          const percentage = parseFloat((res[i].amount / total) * 100).toFixed(
            2
          );
          this.chartOptions.labels.push(res[i].key + " " + percentage + "%");
          this.series.push(parseFloat(res[i].amount.toFixed(2)));
        }
        this.$refs.realtimeChart.updateSeries(
          [
            {
              data: this.series,
            },
          ],
          false,
          true
        );
        return total;
      });
    },
  },
};
</script>

<style>
.data-labels {
  margin-top: 20px;
  margin-left: 40px;
  font-size: 14px;
  font-family: "Lato";
}

.label-color {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  vertical-align: middle;
}
</style>

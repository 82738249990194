import axios from "axios";

export const NetIncome = async (payload) => {
  try {
    return await axios.get(`/analytics/netIncome/${payload}`);
  } catch (error) {
    return error;
  }
};
export const IncomeExpenseMonthly = async (payload) => {
  try {
    return await axios.get(`/analytics/incomeExpenses?year=${payload.year}`);
  } catch (error) {
    return error;
  }
};
export const ExpensesBreakdown = async (payload) => {
  try {
    return await axios.get(`/analytics/expensesBreakdown?year=${payload.year}`);
  } catch (error) {
    return error;
  }
};
export const PayableInvoice = async () => {
  try {
    return await axios.get(`/analytics/payableInvoice/`);
  } catch (error) {
    return error;
  }
};
export const PayableBills = async () => {
  try {
    return await axios.get(`/analytics/payableBills/`);
  } catch (error) {
    return error;
  }
};

export const FiscalYearRange = async () => {
  try {
    return await axios.get(`/analytics//fiscalYearRange`);
  } catch (error) {
    return error;
  }
};

import axios from "axios";

export const CreateInvoice = async (payload) => {
  try {
    return await axios.post(`/invoices/create`, payload);
  } catch (error) {
    return error;
  }
};

export const GetAllInvoices = async (
  customer_id,
  text,
  status,
  from_date,
  to_date,
  date_range,
  limit,
  page,
  age_range,
  is_invoice,
  customer
) => {
  try {
    return await axios.get("/invoices", {
      params: {
        customer_id,
        text,
        status,
        from_date,
        to_date,
        date_range,
        limit,
        page,
        age_range,
        is_invoice,
        customer,
      },
    });
  } catch (error) {
    console.log("🚀 ~ file: invoice.service.js:38 ~ error:", error);

    return error;
  }
};
export const GetAllSentInvoices = async (
  customer_id,
  text,
  status,
  from_date,
  to_date,
  date_range,
  limit,
  page,
  age_range,
  is_invoice,
  customer
) => {
  try {
    console.log({ customer_id, text, status });
    console.log("is invoice = " + is_invoice);
    return await axios.get("/invoices", {
      params: {
        customer_id,
        text,
        status: "sent",
        from_date,
        to_date,
        date_range,
        limit,
        page,
        age_range,
        is_invoice,
        customer,
      },
    });
  } catch (error) {
    console.log("🚀 ~ file: invoice.service.js:38 ~ error:", error);

    return error;
  }
};
export const GetDraftInvoices = async (
  // customer_id,
  // text,
  // from_date,
  // to_date,
  // limit,
  // page,
  // is_invoice
  customer_id,
  text,
  status,
  from_date,
  to_date,
  date_range,
  limit,
  page,
  age_range,
  is_invoice,
  customer
) => {
  try {
    return await axios.get("/invoices", {
      // params: {
      //     customer_id,
      //     text,
      //     status: "draft",
      //     from_date,
      //     to_date,
      //     limit,
      //     page,
      //     is_invoice,
      // },
      params: {
        customer_id,
        text,
        status: "draft",
        from_date,
        to_date,
        date_range,
        limit,
        page,
        age_range,
        is_invoice,
        customer,
      },
    });
  } catch (error) {
    return error;
  }
};

export const GetUnpaidInvoices = async (
  // customer_id,
  // text,
  // from_date,
  // to_date,
  // limit,
  // page,
  // age_range,
  // is_invoice
  customer_id,
  text,
  status,
  from_date,
  to_date,
  date_range,
  limit,
  page,
  age_range,
  is_invoice,
  customer
) => {
  try {
    return await axios.get("/invoices", {
      // params: {
      //     customer_id,
      //     text,
      //     payment_received: false,
      //     status: "unsent",
      //     from_date,
      //     to_date,
      //     limit,
      //     page,
      //     age_range,
      //     is_invoice,
      // },
      // status: ["draft", "unsent", "sent", "pending", "overdue", "partial"],
      params: {
        customer_id,
        text,
        status: ["unsent"],
        from_date,
        to_date,
        payment_received: false,
        date_range,
        limit,
        page,
        age_range,
        is_invoice,
        customer,
      },
    });
  } catch (error) {
    return error;
  }
};
export const GetUnsentInvoices = async (
    // customer_id,
    // text,
    // from_date,
    // to_date,
    // limit,
    // page,
    // age_range,
    // is_invoice
    customer_id,
    text,
    status,
    from_date,
    to_date,
    date_range,
    limit,
    page,
    age_range,
    is_invoice,
    customer,
) => {
    console.log("🚀 ~ file: invoice.service.js:150 ~ limit:", limit)
    console.log("🚀 ~ file: invoice.service.js:150 ~ age_range:", age_range)
    try {
        return await axios.get("/invoices", {
            // params: {
            //     customer_id,
            //     text,
            //     payment_received: false,
            //     status: "unsent",
            //     from_date,
            //     to_date,
            //     limit,
            //     page,
            //     age_range,
            //     is_invoice,
            // },
            params: {
                customer_id,
                text,
                status: ["unsent"],
                from_date,
                to_date,
                payment_received: false,
                date_range,
                limit,
                page,
                age_range,
                is_invoice,
                customer,
            },
        });
    } catch (error) {
        return error;
    }
};
export const GetDuplicateInvoice = async (payload) => {
  try {
    return await axios.post(`/invoices/duplicate`, { invoice: payload });
  } catch (error) {
    return error;
  }
};

export const GetSingleInvoice = async (payload) => {
  try {
    return await axios.get(`/invoices?id=${payload}`);
  } catch (error) {
    return error;
  }
};

export const UpdateInvoice = async (payload) => {
  try {
    return await axios.put(`/invoices/update/${payload.id}`, payload);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const GetInvoicePreview = async (payload) => {
  try {
    return await axios.get(`/invoices/preview/${payload}`);
  } catch (error) {
    return error;
  }
};

export const DeleteInvoice = async (payload) => {
  try {
    return await axios.delete(`/invoices/delete/${payload.id}`);
  } catch (error) {
    return error;
  }
};

export const InvoiceShareLink = async (payload) => {
  try {
    return await axios.put(`/invoices/share/${payload.id}`);
  } catch (error) {
    return error;
  }
};

export const DownloadInvoicePDF = async (id) => {
  try {
    return await axios.get(`/invoices/download/${id}`);
  } catch (error) {
    return error;
  }
};

export const InvoiceAnalytics = async (customer) => {
  try {
    return await axios.get(`/invoices/analytics`, { params: { customer } });
  } catch (error) {
    return error;
  }
};
export const QuoteAnalytics = async () => {
  try {
    return await axios.get(`/invoices/quoteanalytics`);
  } catch (error) {
    return error;
  }
};

export const InvoiceShareEmail = async (id, payload) => {
  return await axios.post(`/invoices/share/email/${id}`, payload);
};

export const ReminderEmail = async (id, payload) => {
  return await axios.post(`/invoices/reminder/email/${id}`, payload);
};

export const SearchInvoice = ({ text }) => {
  return axios.get(`/invoices/search`, {
    params: { text },
  });
};
export const CustomerSummery = async (payload) => {
  try {
    return await axios.get(`/invoices/customer/${payload}`);
  } catch (error) {
    return error;
  }
};
export const GetPackageLimits = async () => {
  try {
    return await axios.get(`/packages/find_limits`);
  } catch (error) {
    return error;
  }
};
export const GetTopUpLink = async () => {
  try {
    return await axios.post(`/packages/generate_invoice_topup_link`);
  } catch (error) {
    return error;
  }
};
export const IncreaseInvoiceLimit = async () => {
  try {
    return await axios.post(`/packages/top_up_invoices`);
  } catch (error) {
    return error;
  }
};

export const dashboardtable = async () => {
  try {
    return await axios.get(`/invoices/dashboardDetails`);
  } catch (error) {
    return error;
  }
};

export const PdfUpload = async (payload) => {
  try {
    return await axios.post(`invoices/pdfs`, payload);
  } catch (error) {
    return error;
  }
};
